import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import Vue from 'vue'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'customerName', label: 'Nombre', sortable: true },
    { key: 'customerMail', label: 'Email', sortable: true },
    { key: 'customerPhone', label: 'Teléfono', sortable: true },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('_id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  watch(store.state.customers.customer_list, () => {
    refetchData()
  })

  const fetchUsers = async () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = store.state.customers.customer_list.filter(
      user => (user.customerName.toLowerCase().includes(queryLowered) || user.customerName.toLowerCase().includes(queryLowered))
        || (user.customerPhone && user.customerPhone.toString().includes(queryLowered))
        || (user.customerMail && user.customerMail.includes(queryLowered)),
    )
    /* eslint-enable  */

    const sortedData = filteredData.sort(sortCompare(sortBy.value))
    if (isSortDirDesc.value) sortedData.reverse()
    const users = paginateArray(sortedData, perPage.value, currentPage.value)
    totalUsers.value = filteredData.length
    return users
  }

  const deleteUser = customerId => {
    Vue.swal({
      title: '¿Seguro?',
      text: 'Esta acción no se puede deshacer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(async result => {
      const delResult = await store.dispatch('customers/deleteCustomers', { customerId })
      if (delResult) {
        fetchUsers()
        Vue.swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'El cliente ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        Vue.swal({
          icon: 'error',
          title: 'Ops!',
          text: 'Algo ha ido mal, inténtalo más tarde.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    })
  }
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    deleteUser,
  }
}
